import { Module, Action, Mutation, getModule } from 'vuex-module-decorators';
import EntityBaseModule from '@/admin/store/entity';
import EntityModel from './entityModel';
// import { modalParams } from './modalParams';

import store from '@/admin/store';
import ResponseHandlerModule from '@/admin/store/modules/responseHandler';
import { getCitiesList } from '@/admin/api/city';
import { getCountriesList } from '@/admin/api/country';
import { activateUser, createUserCard, deactivateUser, editUserCard, getUserCard, getUserRoles } from '@/admin/api/users';
import { UserAddressProcessed, UserApiInterface, UserCityItem } from '@/interfaces/users.interface';
import { clearPhone, formatCreatedAt, generateSort, getApiErrorMessage, showError } from '@/lib/utils/Utils';
import RedirectHandlerModule from '@/admin/store/modules/redirectHandler';
import { processListForSelect } from '@/lib/utils/form';
import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import { getAuctionHouseList } from '@/admin/api/auctionHouse';
import { getGalleryList } from '@/admin/api/gallery';
import { getPainterList } from '@/admin/api/painter';
import { tabsNav } from './tabs';
import { tabsNavInterface } from '@/interfaces/tabs';
import SiteModule from '@/admin/store/site';
import { UserAddressInterface, UserModelInterface } from '@/interfaces/models/user.interface';
import { InputSuggestionItem } from '@/lib/formFactory/inputSuggestion.interface';
import InputEntity from '@/lib/formFactory/inputEntity';
import InputSuggestionEntity from '@/lib/formFactory/inputSuggestionsEntity';
import { addressCreate, addressEdit } from '@/admin/api/address';
import CheckboxEntity from '@/lib/formFactory/checkboxEntity';
import { SelectItem } from '@/lib/formFactory/select.interface';
import { SelectValueDefault } from '@/constants';

export const MODULE_NAME = 'usersEntity';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class UsersEntityModule extends EntityBaseModule {
    cardId: number | null = null;
    model: UserModelInterface;
    userName = '';
    titleReturnData = {
        edit: {
            icon: 'user',
            title: '',
        },
        create: {
            icon: '',
            title: 'Добавление пользователя',
        },
    };
    tabsNav: tabsNavInterface = {};

    constructor(module: UsersEntityModule) {
        super(module);

        const entityModel = new EntityModel();
        this.model = entityModel.model;
    }

    @Mutation
    SET_CARD_ID(id: number | null) {
        this.cardId = id;
    }

    @Mutation
    SET_TABS_NAV(list: { [index: string]: {} }) {
        this.tabsNav = list;
    }

    @Mutation
    SET_USER_NAME(name: string) {
        this.userName = name;
        this.titleReturnData.edit.title = name;
    }

    @Mutation
    SET_FIRST_NAME(name: string) {
        this.model.firstName.value = name;
    }

    @Mutation
    SET_MIDDLE_NAME(name: string) {
        this.model.middleName.value = name;
    }

    @Mutation
    SET_LAST_NAME(name: string) {
        this.model.lastName.value = name;
    }

    @Mutation
    SET_EMAIL(email: string) {
        this.model.email.value = email;
    }

    @Mutation
    SET_PHONE(phone: string) {
        this.model.phone.value = phone;
    }

    @Mutation
    SET_PHONE_CODE(phoneCode: { id: string; value: string }) {
        this.model.phoneCode.current = phoneCode;
    }

    @Mutation
    SET_PHONE_MASK(mask: string) {
        if (this.model.phone.mask) {
            this.model.phone.mask.mask = mask;
        }
    }

    @Mutation
    SET_SITE(phone: string) {
        this.model.site.value = phone;
    }

    @Mutation
    SET_SORT_ORDER(sort: string) {
        this.model.sort.value = sort;
    }

    @Mutation
    SET_SITE_COMMISSION(value: string) {
        this.model.siteCommission.value = value;
    }

    @Mutation
    SET_PASSWORD(password: string) {
        this.model.password.value = password;
    }

    @Mutation
    SET_CONFIRM_PASSWORD(confirmPassword: string) {
        this.model.confirmPassword.value = confirmPassword;
    }

    @Mutation
    SET_IS_ACTIVE(bool: boolean) {
        this.model.isActive.list[0].checked = bool;
    }

    @Mutation
    SET_IS_PAYMENT_DETAILS_CONFIRMED(bool: boolean) {
        this.model.isPaymentDetailsConfirmed.list[0].checked = bool;
    }

    @Mutation
    SET_ADDRESSES(list: UserAddressProcessed[]) {
        this.model.addresses = list;
    }

    @Mutation
    SET_AUCTION_HOUSE_ROLE(params: { id: number; value: string }) {
        this.model.sellerAuctionHouseRole.current = params;
    }

    @Mutation
    SET_SELLER_TYPE(params: { id: number; value: string }) {
        this.model.sellerType.current = params;
    }

    @Mutation
    RESET_SELLER_INFO() {
        this.model.sellerAuctionHouse.current = SelectValueDefault;
        this.model.sellerAuctionHouseRole.current = SelectValueDefault;
        this.model.sellerGallery.current = SelectValueDefault;
        this.model.sellerPainter.current = SelectValueDefault;
    }

    @Mutation
    SET_BALANCE(balance: number | null) {
        this.model.balance = `${balance} ₽`;
    }

    @Mutation
    SET_IS_ONLINE(isOnline: boolean) {
        this.model.isOnline = isOnline;
    }

    @Mutation
    SET_STATUS(status: string) {
        this.model.status = status;
    }

    @Mutation
    SET_PUBLIC_ROLES_SELECTED(selected: SelectItem) {
        this.model.publicRole.current = selected;
    }

    @Mutation
    SET_PUBLIC_ROLES_LIST(list: SelectItem[]) {
        this.model.publicRole.list = list;
    }

    @Action({ rawError: true })
    initTabsItems() {
        this.SET_TABS_NAV(tabsNav);
    }

    @Action({ rawError: true })
    async initForm() {
        const roles = await getUserRoles();
        const publicRoleEntries = Object.entries(roles).filter(
            (entry) => entry[0].indexOf('ROLE_USER') === 0 && entry[0] !== 'ROLE_USER_LEVEL3',
        );
        const list = publicRoleEntries.map((entry) => ({ id: entry[0], value: entry[1] }));

        this.SET_PUBLIC_ROLES_LIST(list);
    }

    @Action({ rawError: true })
    async initCardById(userId: number | string) {
        this.getUser(userId);
    }

    @Action({ rawError: true })
    async getUser(userId: number | string) {
        this.SET_IS_LOADING(true);

        try {
            const result = await getUserCard(userId);

            this.setInfo(result);
        } catch (error) {
            ResponseHandlerModule.showNotify({ message: await getApiErrorMessage(error), type: 'fail' });
        }

        this.SET_IS_LOADING(false);
    }

    @Action({ rawError: true })
    async setInfo(data: UserApiInterface) {
        if (!data) {
            return;
        }

        this.context.commit('SET_CARD_ID', data.id);

        if (data.phoneNumber) {
            const code: any = this.codes.find((code: { code: string }) => code.code === `+${data.phoneNumber.code}`);
            this.context.commit('SET_PHONE_CODE', { id: code.code, value: code.code });
            this.context.commit('SET_PHONE_MASK', code.mask);
            this.context.commit('SET_PHONE', data.phoneNumber ? data.phoneNumber.number : '');
        }

        this.context.commit('SET_EMAIL', data.email ? data.email : '');

        const name = [data.firstName, data.middleName, data.lastName].filter((name) => name != null).join(' ');
        this.context.commit('SET_USER_NAME', name);

        this.context.commit('SET_FIRST_NAME', data.firstName ? data.firstName : '');
        this.context.commit('SET_MIDDLE_NAME', data.middleName ? data.middleName : '');
        this.context.commit('SET_LAST_NAME', data.lastName ? data.lastName : '');

        this.context.commit('SET_IS_ACTIVE', data.active ?? false);
        this.context.commit(
            'SET_IS_PAYMENT_DETAILS_CONFIRMED',
            data.isPaymentDetailsConfirmed ? data.isPaymentDetailsConfirmed : false,
        );
        this.context.commit('SET_CREATED_AT', data.createdAt ? formatCreatedAt(data.createdAt) : '');

        if (data.sellerAuctionHouse) {
            const sellerType = this.model.sellerType.list.filter((item) => item.id === 'auction_house')[0];
            this.context.commit('SET_SELLER_TYPE', sellerType);

            this.updateSuggestionsSelectedByType({
                selected: { id: data.sellerAuctionHouse.id, value: data.sellerAuctionHouse.name },
                type: 'sellerAuctionHouse',
            });

            const auctionHouseRole = this.model.sellerAuctionHouseRole.list.filter(
                (item) => item.id === data.sellerAuctionHouseRole,
            )[0];
            this.context.commit('SET_AUCTION_HOUSE_ROLE', auctionHouseRole);
        }

        if (data.sellerGallery) {
            const sellerType = this.model.sellerType.list.filter((item) => item.id === 'gallery')[0];
            this.context.commit('SET_SELLER_TYPE', sellerType);

            this.updateSuggestionsSelectedByType({
                selected: { id: data.sellerGallery.id, value: data.sellerGallery.name },
                type: 'sellerGallery',
            });
        }

        if (data.sellerPainter) {
            const sellerType = this.model.sellerType.list.filter((item) => item.id === 'painter')[0];
            this.context.commit('SET_SELLER_TYPE', sellerType);

            this.updateSuggestionsSelectedByType({
                selected: { id: data.sellerPainter.id, value: data.sellerPainter.name },
                type: 'sellerPainter',
            });
        }

        const addressesProcessed: any[] = [];
        if (data.addresses.length > 0) {
            const addresses = data.addresses.sort((a: any, b: any) => (a.id > b.id ? 1 : -1));
            addresses.forEach(async (item: any) => {
                const address = await this.processAddress(item);
                addressesProcessed.push(address);
            });
        } else {
            const emptyUserAddress = {
                id: 'new',
                city: { id: 0, name: '', country: { id: 0, name: '' } },
                address: '',
                isDefault: true,
                zip: '',
            };
            addressesProcessed[0] = await this.processAddress(emptyUserAddress);
        }

        this.context.commit('SET_ADDRESSES', addressesProcessed);

        const publicRole = data.roles.find((role) => role.indexOf('ROLE_USER') === 0);
        if (publicRole) {
            const role = this.model.publicRole.list.find((role) => role.id === publicRole);
            this.SET_PUBLIC_ROLES_SELECTED(role || SelectValueDefault);
        }

        this.SET_BALANCE(data.balance ?? 0);
        this.SET_IS_ONLINE(data.isOnline);
        this.updateStatus(data.status);
    }

    @Action({ rawError: true })
    updateStatus(status: string | null) {
        this.SET_STATUS(status || 'allowed');
    }

    @Action({ rawError: true })
    processAddressCity(data: any) {
        const entity = new InputSuggestionEntity(`city_${data.id}`);

        return {
            ...entity.entity[`city_${data.id}`],
            title: 'Город',
            value: data.name,
            current: {
                id: data.id,
                value: data.name,
            },
        };
    }

    @Action({ rawError: true })
    processAddressCountry(data: { id: number; name: string }) {
        const entity = new InputSuggestionEntity(`country_${data.id}`);

        return {
            ...entity.defaultValue,
            title: 'Страна',
            value: data.name,
            current: {
                id: data.id,
                value: data.name,
            },
        };
    }

    @Action({ rawError: true })
    processAddressAddress(address: string) {
        const entity = new InputEntity('address');

        return {
            ...entity.defaultValue,
            title: 'Адрес',
            value: address,
            clearAction: true,
        };
    }

    @Action({ rawError: true })
    processAddressZip(zip: number | string) {
        const entity = new InputEntity('zip');

        return {
            ...entity.defaultValue,
            title: 'ZIP',
            value: String(zip),
        };
    }

    @Action({ rawError: true })
    processIsDefault(isDefault: boolean) {
        const entity = new CheckboxEntity('isDefault');

        return {
            ...entity.defaultValue,
            list: {
                0: {
                    id: 1,
                    name: 'Использовать по умолчанию',
                    checked: isDefault,
                },
            },
        };
    }

    @Action({ rawError: true })
    async processAddress(data: UserCityItem) {
        const id = data.id;
        const city = await this.processAddressCity(data.city);
        const country = await this.processAddressCountry(data.city.country);
        const address = await this.processAddressAddress(data.address);
        const zip = await this.processAddressZip(data.zip);
        const isDefault = await this.processIsDefault(data.isDefault);

        return {
            id,
            city,
            country,
            address,
            zip,
            isDefault,
        };
    }

    @Action({ rawError: true })
    async createNewUserAddress() {
        const enptyUserAddress = {
            id: 'new',
            city: {
                id: 0,
                name: '',
                country: {
                    id: 0,
                    name: '',
                },
            },
            address: '',
            zip: '',
            isDefault: false,
        };

        const newAddressEntity = [await this.processAddress(enptyUserAddress)];
        this.context.commit('SET_ADDRESSES', [...this.model.addresses, ...newAddressEntity]);
    }

    @Action({ rawError: true })
    removeUserAddress(id: number) {
        const addresses = this.model.addresses.filter((item) => item.id != id);
        this.context.commit('SET_ADDRESSES', addresses);
    }

    @Action({ rawError: true })
    updateUserAddress(params: { id: number; value: string }) {
        const result = this.model.addresses.map((item) => {
            if (item.id == params.id) {
                item.address.value = params.value;
            }

            return item;
        });

        this.context.commit('SET_ADDRESSES', result);
    }

    @Action({ rawError: true })
    updateUserAddressZip(params: { id: number; value: string }) {
        const result = this.model.addresses.map((item) => {
            if (item.id == params.id) {
                item.zip.value = params.value;
            }

            return item;
        });

        this.context.commit('SET_ADDRESSES', result);
    }

    @Action({ rawError: true })
    updateUserAddressIsDefault(params: { id: number; value: boolean }) {
        const result = this.model.addresses.map((item) => {
            if (item.id == params.id) {
                item.isDefault.list[0].checked = params.value;

                return item;
            }

            item.isDefault.list[0].checked = false;

            return item;
        });

        this.context.commit('SET_ADDRESSES', result);
    }

    @Action({ rawError: true })
    updateSelectedUserCity(params: { id: number; value: { id: number; value: string } }) {
        const result = this.model.addresses.map((item) => {
            if (item.id == params.id) {
                item.city.value = params.value.value;
                item.city.current = params.value;
            }

            return item;
        });

        this.context.commit('SET_ADDRESSES', result);
    }

    @Action({ rawError: true })
    updateSearchUserCountry(params: { id: number; value: string }) {
        const newParams = { ...params, type: 'country' };
        this.updateSearchUserValue(newParams);
        this.updateSearchUserHintsList(newParams);
    }

    @Action({ rawError: true })
    updateSelectedUserCountry(params: { id: number; value: { id: number; value: string } }) {
        const result = this.model.addresses.map((item) => {
            if (item.id == params.id) {
                item.country.value = params.value.value;
                item.country.current = { id: params.value.id.toString(), value: params.value.value };
                item.city.value = '';
                item.city.current = { id: '0', value: '' };
                item.city.hintsList = [];
            }

            return item;
        });

        this.context.commit('SET_ADDRESSES', result);
    }

    @Action({ rawError: true })
    updateSearchUserValue(params: { id: number; value: string; type: string }) {
        const result = this.model.addresses.map((item) => {
            if (item.id === params.id) {
                item[params.type].value = params.value;
            }

            return item;
        });

        this.context.commit('SET_ADDRESSES', result);
    }

    @Action({ rawError: true })
    updateSearchUserCity(params: { id: number; value: any }) {
        const newParams = { ...params, type: 'city' };
        this.updateSearchUserValue(newParams);
        this.updateSearchUserHintsList(newParams);
    }

    @Action({ rawError: true })
    async updateSearchUserHintsList(params: { id: number; value: string; type: string }) {
        const sort = 'sort[0][id]=name&sort[0][value]=ASC&';
        let filter = `filters[0][id]=name&filters[0][value]=${params.value}`;

        if (params.type === 'city') {
            const country = this.model.addresses.filter((address) => address.id === params.id)[0]?.country?.current;

            filter += (country as InputSuggestionItem).id
                ? `&filters[1][id]=country&filters[1][value]=${(country as InputSuggestionItem).id}`
                : '';
        }

        const table =
            params.type === 'country'
                ? await getCountriesList(1, sort, filter, 50)
                : await getCitiesList(1, sort, filter, 50);

        const rows = await processListForSelect(table.rows);

        const result = this.model.addresses.map((item) => {
            if (item.id === params.id) {
                item[params.type].hintsList = rows;
            }

            return item;
        });

        this.context.commit('SET_ADDRESSES', result);
    }

    @Action({ rawError: true })
    updateFirstName(name: string) {
        if (name !== '') {
            this.context.commit('RESET_ERROR_BY_FIELD', 'firstName');
        }

        this.context.commit('SET_FIRST_NAME', name);
    }

    @Action({ rawError: true })
    updateMiddleName(name: string) {
        this.context.commit('SET_MIDDLE_NAME', name);
    }

    @Action({ rawError: true })
    updateLastName(name: string) {
        this.context.commit('SET_LAST_NAME', name);
    }

    @Action({ rawError: true })
    updatePhone(phone: string) {
        this.context.commit('RESET_ERROR_BY_FIELD', 'phone');
        this.context.commit('SET_PHONE', phone);
    }

    @Action({ rawError: true })
    updatePhoneCode(phoneCode: { id: string; value: string }) {
        const code: any = this.codes.find((code: { code: string }) => code.code === phoneCode.value);
        this.context.commit('SET_PHONE_MASK', code.mask);

        this.context.commit('RESET_ERROR_BY_FIELD', 'phoneCode');
        this.context.commit('SET_PHONE_CODE', phoneCode);
    }

    @Action({ rawError: true })
    updateEmail(email: string) {
        this.context.commit('RESET_ERROR_BY_FIELD', 'email');
        this.context.commit('SET_EMAIL', email);
    }

    @Action({ rawError: true })
    searchAuctionHouse(search: string) {
        this.searchSellerByType({ search, type: 'sellerAuctionHouse' });
    }

    @Action({ rawError: true })
    searchGallery(search: string) {
        this.searchSellerByType({ search, type: 'sellerGallery' });
    }

    @Action({ rawError: true })
    searchPainter(search: string) {
        this.searchSellerByType({ search, type: 'sellerPainter' });
    }

    @Action({ rawError: true })
    async searchSellerByType(params: { search: string; type: string }) {
        let table: TableApiInterface | null = null;
        this.SET_SUGGESTIONS_VALUE_BY_TYPE({ value: params.search, type: params.type });
        const sort = generateSort({ id: 'name', value: 'ASC' });

        if (params.type === 'sellerAuctionHouse') {
            table = await getAuctionHouseList(1, sort, `filters[0][id]=name&filters[0][value]=${params.search}`, 50);
        }
        if (params.type === 'sellerGallery') {
            table = await getGalleryList(1, sort, `filters[0][id]=name&filters[0][value]=${params.search}`, 50);
        }
        if (params.type === 'sellerPainter') {
            table = await getPainterList(1, sort, `filters[0][id]=name&filters[0][value]=${params.search}`, 50);
        }

        if (!table?.rows.length) {
            return;
        }

        this.SET_SUGGESTIONS_HINTS_LIST_BY_TYPE({
            list: await processListForSelect(Object.values(table.rows)),
            type: params.type,
        });
    }

    @Action({ rawError: true })
    updateAuctionHouseRole(params: { id: number; value: string }) {
        this.context.commit('RESET_ERROR_BY_FIELD', 'sellerAuctionHouseRole');
        this.context.commit('SET_AUCTION_HOUSE_ROLE', params);
    }

    @Action({ rawError: true })
    updateIsPaymentDetailsConfirmedHandler(bool: boolean) {
        this.context.commit('SET_IS_PAYMENT_DETAILS_CONFIRMED', bool);
    }

    @Action({ rawError: true })
    updateIsActive(bool: boolean) {
        this.context.commit('SET_IS_ACTIVE', bool);
    }

    @Action({ rawError: true })
    updateSellerType(params: { id: number; value: string }) {
        this.context.commit('RESET_ERROR_BY_FIELD', 'sellerType');
        this.context.commit('RESET_ERROR_BY_FIELD', 'sellerAuctionHouse');
        this.context.commit('RESET_ERROR_BY_FIELD', 'sellerAuctionHouseRole');
        this.context.commit('RESET_ERROR_BY_FIELD', 'sellerGallery');
        this.context.commit('RESET_ERROR_BY_FIELD', 'sellerPainter');

        this.context.commit('RESET_SELLER_INFO');
        this.context.commit('SET_SELLER_TYPE', params);
    }

    @Action({ rawError: true })
    clearSellerType() {
        this.updateSellerType(SelectValueDefault);
    }

    @Action({ rawError: true })
    clearPublicRole() {
        this.updatePublicRoleSelected(SelectValueDefault);
    }

    @Action({ rawError: true })
    updatePublicRoleSelected(selected: SelectItem) {
        this.SET_PUBLIC_ROLES_SELECTED(selected);
    }

    @Action({ rawError: true })
    prepareAddress(data: UserAddressInterface) {
        return {
            customer: String(this.cardId as number),
            city: String((data.city.current as InputSuggestionItem).id),
            address: data.address.value,
            zip: data.zip.value,
            default: String(data.isDefault.list[0].checked ? 1 : 0),
        };
    }

    @Action({ rawError: true })
    async sendAddressEdit(addressId: number) {
        const address = this.model.addresses.find((address) => address.id === addressId);

        if (!address) {
            return;
        }

        try {
            const requestData = await this.prepareAddress(address);
            await addressEdit(address.id as number, requestData);

            ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });
        } catch (error) {
            ResponseHandlerModule.showNotify({ message: getApiErrorMessage(error), type: 'fail' });
        }
    }

    @Action({ rawError: true })
    async sendAddressCreate() {
        const address = this.model.addresses.find((address) => address.id === 'new');

        if (!address) {
            return;
        }

        try {
            const result = await addressCreate(await this.prepareAddress(address));
            const newAddress = await this.processAddress(result);

            this.SET_ADDRESSES(
                [...this.model.addresses].map((address) => address.id === 'new' ? newAddress : address),
            );

            ResponseHandlerModule.showNotify({ message: 'Адрес успешно добавлен', type: 'ok' });
        } catch (error) {
            ResponseHandlerModule.showNotify({ message: getApiErrorMessage(error), type: 'fail' });
        }
    }

    @Action({ rawError: true })
    async sendEdit() {
        const isError = await this.validate();

        if (isError) {
            ResponseHandlerModule.showNotify({ message: 'Заполните обязательные поля', type: 'fail' });

            return;
        }

        SiteModule.SET_IS_BLOCK(true);

        try {
            const formData = await this.prepareRequestData();
            let result: any;

            if (this.cardId != null) {
                result = await editUserCard(this.cardId, formData);
            }

            if (!result.message) {
                ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });
            } else {
                ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
            }
        } catch (error) {
            ResponseHandlerModule.showNotify({ message: await getApiErrorMessage(error), type: 'fail' });
        }

        SiteModule.SET_IS_BLOCK(false);
    }

    @Action({ rawError: true })
    async sendAdd(redirect: any = null) {
        const isError = await this.validate();

        if (isError === true) {
            ResponseHandlerModule.showNotify({ message: 'Заполните обязательные поля', type: 'fail' });

            return;
        }

        SiteModule.SET_IS_BLOCK(true);

        try {
            const formData = await this.prepareRequestData();
            const result = (await createUserCard(formData)) as any;

            if (!result.message) {
                ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });
                RedirectHandlerModule.changeUrl(redirect);
            } else {
                ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
            }
        } catch (error) {
            ResponseHandlerModule.showNotify({ message: await getApiErrorMessage(error), type: 'fail' });
        }

        SiteModule.SET_IS_BLOCK(false);
    }

    @Action({ rawError: true })
    prepareRequestData() {
        const data: any = {};

        if (this.model.firstName.value) {
            data['firstName'] = this.model.firstName.value;
        }

        if (this.model.middleName.value) {
            data['middleName'] = this.model.middleName.value;
        }

        if (this.model.lastName.value) {
            data['lastName'] = this.model.lastName.value;
        }

        if (this.model.email.value) {
            data['email'] = this.model.email.value;
        }

        if (this.model.phone.value) {
            data['phone'] = clearPhone(this.model.phoneCode.current.value + this.model.phone.value);
        }

        if (this.model.password.value) {
            data['password'] = this.model.password.value;
        }

        data['active'] = this.model.isActive.list[0].checked ? 1 : 0;

        // data['isPaymentDetailsConfirmed'] = this.model.isPaymentDetailsConfirmed.list[0].checked;

        if ((this.model.sellerAuctionHouse.current as InputSuggestionItem).id) {
            data['sellerAuctionHouse'] = (this.model.sellerAuctionHouse.current as InputSuggestionItem).id;
            data['sellerAuctionHouseRole'] = (this.model.sellerAuctionHouseRole.current as InputSuggestionItem).id;
        }

        if ((this.model.sellerGallery.current as InputSuggestionItem).id) {
            data['sellerGallery'] = (this.model.sellerGallery.current as InputSuggestionItem).id;
        }

        if ((this.model.sellerPainter.current as InputSuggestionItem).id) {
            data['sellerPainter'] = (this.model.sellerPainter.current as InputSuggestionItem).id;
        }

        if (this.model.publicRole.current.id) {
            data['publicRole'] = (this.model.publicRole.current.id as string).match(/\d+/);
        }

        this.model.addresses.forEach((address, index) => {
            if (address.id === 'new') {
                return;
            }

            data[`addresses[${index}]`] = address.id;
        });

        if (this.model.status.length) {
            data['status'] = this.model.status;
        }

        return data;
    }

    @Action({ rawError: true })
    async validate() {
        const requiredFields = ['firstName', 'email', 'phone'];

        const isPasswordCheckRequired =
            this.cardId === null || (this.cardId !== null && this.model.password.value.length > 0);
        let isPasswordCheckSuccess: boolean | null = null;

        this.context.commit('SET_EMAIL', await this.model.email.value.replaceAll(' ', ''));

        this.setValidateState({ index: 'firstName', field: this.model.firstName });
        this.setValidateEmail({ index: 'email', field: this.model.email });
        this.setValidatePhone({ index: 'phone', field: this.model.phone });

        if (isPasswordCheckRequired) {
            requiredFields.push('password', 'confirmPassword');
            this.setValidateState({ index: 'password', field: this.model.password });
            this.setValidateState({ index: 'confirmPassword', field: this.model.confirmPassword });
        }

        if (this.model.sellerType.current.id === 'auction_house') {
            this.setValidateSelect({ index: 'sellerAuctionHouse', field: this.model.sellerAuctionHouse });
            requiredFields.push('sellerAuctionHouse');
        }

        if ((this.model.sellerAuctionHouse.current as InputSuggestionItem).id) {
            this.setValidateSelect({ index: 'sellerAuctionHouseRole', field: this.model.sellerAuctionHouseRole });
            requiredFields.push('sellerAuctionHouseRole');
        }

        if (this.model.sellerType.current.id === 'gallery') {
            this.setValidateSelect({ index: 'sellerGallery', field: this.model.sellerGallery });
            requiredFields.push('sellerGallery');
        }

        if (this.model.sellerType.current.id === 'painter') {
            this.setValidateSelect({ index: 'sellerPainter', field: this.model.sellerPainter });
            requiredFields.push('sellerPainter');
        }

        if (isPasswordCheckRequired && this.model.password.value) {
            isPasswordCheckSuccess = this.model.password.value === this.model.confirmPassword.value;
        }

        if (isPasswordCheckSuccess === false) {
            this.context.commit('SET_ERROR_BY_FIELD', { field: 'confirmPassword', message: 'Пароли не совпадают' });
        }

        return requiredFields.filter((item) => this.model[item].error.class).length > 0;
    }

    @Action({ rawError: true })
    updatePassword(password: string) {
        let errorMessage = '';
        if (password.length < 8) {
            errorMessage = 'Пароль должен содержать более 8 символов';
        } else if (!password.match(/[a-zA-Z]/) || !password.match(/[0-9]/)) {
            errorMessage = 'Пароль должен состоять из латинских букв и цифр';
        }

        errorMessage
            ? this.context.commit('SET_ERROR_BY_FIELD', { field: 'password', message: errorMessage })
            : this.context.commit('RESET_ERROR_BY_FIELD', 'password');

        this.context.commit('SET_PASSWORD', password);
    }

    @Action({ rawError: true })
    updateConfirmPassword(password: string) {
        if (this.model.password.value.indexOf(password) !== 0) {
            this.context.commit('SET_ERROR_BY_FIELD', { field: 'confirmPassword', message: 'Пароли не совпадают' });
            this.context.commit('SET_CONFIRM_PASSWORD', password);

            return;
        }

        this.context.commit('RESET_ERROR_BY_FIELD', 'confirmPassword');
        this.context.commit('SET_CONFIRM_PASSWORD', password);
    }

    @Action({ rawError: true })
    async sendDisallowCreation() {
        if (!this.cardId) {
            return;
        }

        SiteModule.SET_IS_BLOCK(true);

        try {
            const { status } = await deactivateUser(this.cardId);
            this.updateStatus(status);
        } catch (error) {
            showError(getApiErrorMessage(error));
        }

        SiteModule.SET_IS_BLOCK(false);
    }

    @Action({ rawError: true })
    async sendAllowCreation() {
        if (!this.cardId) {
            return;
        }

        SiteModule.SET_IS_BLOCK(true);

        try {
            const { status } = await activateUser(this.cardId);
            this.updateStatus(status);
        } catch (error) {
            showError(getApiErrorMessage(error));
        }

        SiteModule.SET_IS_BLOCK(false);
    }

    @Action({ rawError: true })
    reset() {
        this.context.commit('SET_IS_LOADING', true);
        this.context.commit('SET_CARD_ID', null);
        this.context.commit('SET_PHONE', '');
        this.context.commit('SET_EMAIL', '');
        this.context.commit('SET_USER_NAME', '');
        this.context.commit('SET_ADDRESSES', []);

        this.context.commit('SET_FIRST_NAME', '');
        this.context.commit('SET_MIDDLE_NAME', '');
        this.context.commit('SET_LAST_NAME', '');

        this.context.commit('SET_IS_ACTIVE', false);
        this.context.commit('SET_IS_PAYMENT_DETAILS_CONFIRMED', false);
        this.context.commit('SET_CREATED_AT', '');

        this.context.commit('SET_SELLER_TYPE', SelectValueDefault);
        this.context.commit('SET_AUCTION_HOUSE_ROLE', SelectValueDefault);
        this.resetSuggestionsStateByType('sellerAuctionHouse');
        this.resetSuggestionsStateByType('sellerGallery');
        this.resetSuggestionsStateByType('sellerPainter');

        this.context.commit('SET_PASSWORD', '');
        this.context.commit('SET_CONFIRM_PASSWORD', '');

        this.SET_PUBLIC_ROLES_LIST([]);
        this.SET_PUBLIC_ROLES_SELECTED(SelectValueDefault);

        this.SET_BALANCE(null);
        this.SET_IS_ONLINE(false);

        SiteModule.SET_IS_BLOCK(false);

        this.resetErrorState();
    }

    @Action({ rawError: true })
    resetErrorState() {
        this.context.commit('RESET_ERROR_BY_FIELD', 'sellerAuctionHouse');
        this.context.commit('RESET_ERROR_BY_FIELD', 'sellerAuctionHouseRole');
        this.context.commit('RESET_ERROR_BY_FIELD', 'sellerGallery');
        this.context.commit('RESET_ERROR_BY_FIELD', 'sellerPainter');
    }
}

export default getModule(UsersEntityModule);
