import { Module, Action, getModule } from 'vuex-module-decorators';
import TransactionsBaseModule from '@/admin/store/transactions';
import PageEntity from '@/lib/layouts/page/pageEntity';

import store from '@/admin/store';
import { getTransactionsList } from '@/admin/api/transaction';

const MODULE_NAME = 'userTransactions';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class UserTransactionsModule extends TransactionsBaseModule {
    constructor(module: UserTransactionsModule) {
        super(module);

        this.isLoading = false;

        const page = new PageEntity();
        page.setSortName('UserTransactionsSort');
        page.setDefaultSortField('createdAt');
        this.pageSettings = page.values;
    }

    @Action({ rawError: true })
    async getTable(params: { customerId?: number; sellerName?: string; catalogName?: string }) {
        this.SET_IS_LOADING(true);
        this.SET_REQUEST_PARAMS(params);

        const sort = await this.getSortForRequest();
        let filter = params.customerId ? `&filters[0][id]=customer&filters[0][value]=${params.customerId}` : '';
        filter += '&filters[1][id]=subjectType&filters[1][value]=user';
        const promises = [
            getTransactionsList(this.pageSettings.pageCurrent, sort, filter, +this.pageSettings.pageAmountItems),
        ];

        if (!this.operationNames.length) {
            promises.push(this.initOperationNames());
        }

        if (!this.statuses.length) {
            promises.push(this.initOrderStatuses());
        }

        const [result] = await Promise.all(promises);

        await this.setInfo(result);
        this.SET_IS_LOADING(false);
    }
}
export default getModule(UserTransactionsModule);
