
import { Component, Vue, Watch } from 'vue-property-decorator';
import TableBlock from '@/components/table/Table.vue';
import AppHeaderMain from '@/admin/layouts/partials/AppHeaderMain.vue';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import TitleReturn from '@/components/TitleReturn.vue';

import BtnDefault from '@/components/BtnDefault.vue';
import BtnSubmitFake from '@/components/buttons/BtnSubmitFake/BtnSubmitFake.vue';
import InputBlock from '@/components/form/InputMain.vue';
import InputPassword from '@/components/form/InputPassword.vue';
import SelectCustom from '@/components/select/SelectCustom.vue';
import CheckboxCustom from '@/components/form/Checkbox.vue';
import BtnSubmit from '@/components/buttons/BtnSubmit/BtnSubmit.vue';
import Loading from '@/components/Loading.vue';
import AddressComponent from './_address.vue';
import InputSuggestions from '@/components/input/InputSuggestions.vue';

import IconFont from '@/components/icons/IconFont.vue';
import IconAdd from '@/components/icons/Add.vue';

import Inputmask from 'inputmask';
import UsersEntity from '@/admin/store/users/entity';
import RemoveModal from '@/components/modules/removeModal.vue';
import { InputSuggestionItem } from '@/lib/formFactory/inputSuggestion.interface';
import { SelectItem } from '@/lib/formFactory/select.interface';

@Component({
    components: {
        RemoveModal,
        TableBlock,
        AppHeaderMain,
        TabsNav,
        TitleReturn,
        BtnDefault,
        BtnSubmitFake,
        InputBlock,
        InputPassword,
        SelectCustom,
        CheckboxCustom,
        BtnSubmit,
        IconFont,
        IconAdd,
        Loading,
        InputSuggestions,
        AddressComponent,
    },
})
export default class UserCard extends Vue {
    componentCounter = 0;
    isPasswordDisabled = true;

    get isCreatePage(): boolean {
        return this.$route.name === 'user_create';
    }

    get isShowUserAddressAdd(): boolean {
        const lastAddress = this.model.addresses[this.model.addresses.length - 1];

        return (
            lastAddress?.id !== 'new' &&
            !!(lastAddress?.country.current as InputSuggestionItem)?.id &&
            !!(lastAddress?.city.current as InputSuggestionItem)?.id &&
            !!lastAddress?.address?.value &&
            !!lastAddress?.zip?.value
        );
    }

    get model() {
        return UsersEntity.model;
    }

    get userName() {
        return UsersEntity.userName;
    }

    get isLoading() {
        return UsersEntity.isLoading;
    }

    get codes() {
        return UsersEntity.codes;
    }

    get codesList() {
        return this.codes.map((code: any) => ({
            id: code.id,
            value: code.code,
        }));
    }

    get isActiveElement() {
        return UsersEntity.model.isActive.list[0];
    }

    get isAllowedToCreate(): boolean {
        return UsersEntity.model.status === 'allowed';
    }

    @Watch('isActiveElement', { deep: true })
    onSsActiveElement() {
        this.componentCounter++;
    }

    @Watch('model', { deep: true })
    changePhoneMask() {
        this.initPhoneMask();
    }
    
    updateUser(): void {
        UsersEntity.getUser(UsersEntity.cardId || this.$route.params.userId);
    }

    calculateGridStartRow(id: number) {
        return id > 0 ? 2 + id * 4 : 2;
    }

    calculateGridEndRow(id: number) {
        return id > 0 ? 6 + id * 4 : 6;
    }

    updateFirstName(value: string) {
        UsersEntity.updateFirstName(value);
    }

    updateMiddleName(value: string) {
        UsersEntity.updateMiddleName(value);
    }

    updateLastName(value: string) {
        UsersEntity.updateLastName(value);
    }

    updatePhone(value: string) {
        UsersEntity.updatePhone(value);
    }

    updatePhoneCode(value: { id: string; value: string }) {
        UsersEntity.updatePhoneCode(value);
    }

    updateEmail(value: string) {
        UsersEntity.updateEmail(value);
    }

    updatePassword(value: string) {
        UsersEntity.updatePassword(value);
    }

    updateConfirmPassword(value: string) {
        UsersEntity.updateConfirmPassword(value);
    }

    isPaymentDetailsConfirmedHandler(params: { name: string; id: number; bool: boolean }) {
        UsersEntity.updateIsPaymentDetailsConfirmedHandler(params.bool);
    }

    isActiveHandler(params: { name: string; id: number; bool: boolean }) {
        UsersEntity.updateIsActive(params.bool);
    }

    searchSeller(params: { search: string; type: string }) {
        UsersEntity.searchSellerByType(params);
    }

    updateSelectedAuctionHouse(params: InputSuggestionItem) {
        UsersEntity.updateSuggestionsSelectedByType({ selected: params, type: 'sellerAuctionHouse' });
    }

    updateSellerAuctionHouseRole(params: { id: number; value: string }) {
        UsersEntity.updateAuctionHouseRole(params);
    }

    updateSelectedGallery(params: { id: number; value: string }) {
        UsersEntity.updateSuggestionsSelectedByType({ selected: params, type: 'sellerGallery' });
    }

    updateSelectedPainter(params: { id: number; value: string }) {
        UsersEntity.updateSuggestionsSelectedByType({ selected: params, type: 'sellerPainter' });
    }

    updateSellerType(params: { id: number; value: string }) {
        UsersEntity.updateSellerType(params);
    }

    updatePublicRole(params: SelectItem): void {
        UsersEntity.updatePublicRoleSelected(params);
    }

    addUserAddress() {
        UsersEntity.createNewUserAddress();
    }

    send() {
        if (this.$route.params.userId) {
            this.sendEdit();

            return;
        }
        this.sendAdd();
    }

    sendEdit() {
        UsersEntity.sendEdit();
    }

    sendAdd() {
        UsersEntity.sendAdd({ name: 'users' });
    }

    sendDisallowCreation(): void {
        UsersEntity.sendDisallowCreation();
    }

    sendAllowCreation(): void {
        UsersEntity.sendAllowCreation();
    }

    returnBefore() {
        this.$router.push({ name: 'users' });
    }

    initPhoneMask() {
        const phoneMask = new Inputmask(UsersEntity.model.phone.mask);
        const inputsPhone = document.getElementsByClassName('j_phone_mask_wrapper');

        for (const item of Object.values(inputsPhone)) {
            phoneMask.mask(item.getElementsByTagName('input')[0]);
        }
    }

    initEmailMask() {
        const emailMask = new Inputmask(UsersEntity.model.email.mask);
        const inputsEmail = document.getElementsByClassName('j_email_mask_wrapper');

        for (const item of Object.values(inputsEmail)) {
            emailMask.mask(item.getElementsByTagName('input')[0]);
        }
    }

    async mounted() {
        this.initEmailMask();
        this.initPhoneMask();
        UsersEntity.updateIsLoading(false);
        this.isPasswordDisabled = false;
    }
}
