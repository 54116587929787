
import { Vue, Component, Prop } from 'vue-property-decorator';
import InputSuggestions from '@/components/input/InputSuggestions.vue';
import TextareaCustom from '@/components/form/Textarea.vue';
import InputDefault from '@/components/form/InputMain.vue';
import BtnSubmitFake from '@/components/buttons/BtnSubmitFake/BtnSubmitFake.vue';
import SiteModule from '@/admin/store/site';
import RemoveModal from '@/components/modules/removeModal.vue';
import CheckboxCustom from '@/components/form/CheckboxDefault.vue';

import UsersEntity from '@/admin/store/users/entity';
import { InputSuggestion, InputSuggestionItem } from '@/lib/formFactory/inputSuggestion.interface';
import { Input } from '@/lib/formFactory/input.interface';

@Component({
    components: {
        InputSuggestions,
        TextareaCustom,
        CheckboxCustom,
        BtnSubmitFake,
        InputDefault,
        RemoveModal,
    },
})
export default class UserAddressComponent extends Vue {
    @Prop() readonly address!: {
        id: number | string;
        country: InputSuggestion;
        city: InputSuggestion;
        address: Input;
        zip: Input;
    };

    isAddressChanged = false;
    isRemoved = false;

    get isSaveChangesShow(): boolean {
        return (
            (this.address.city.current as InputSuggestionItem).value === this.address.city.value &&
            !!this.address.address.value.length &&
            !!this.address.zip.value.length &&
            this.isAddressChanged
        );
    }

    updateSearchCountry(search: string, addressId: number): void {
        UsersEntity.updateSearchUserCountry({ id: addressId, value: search });
    }

    updateSelectedCountry(selected: { id: number; value: string }, addressId: number): void {
        UsersEntity.updateSelectedUserCountry({ id: addressId, value: selected });
        this.isAddressChanged = true;
    }

    updateSearchCity(search: string, addressId: number): void {
        UsersEntity.updateSearchUserCity({ id: addressId, value: search });
    }

    updateSelectedCity(selected: { id: number; value: string }, addressId: number): void {
        UsersEntity.updateSelectedUserCity({ id: addressId, value: selected });
        this.isAddressChanged = true;
    }

    updateAddress(params: string, addressId: number): void {
        UsersEntity.updateUserAddress({ id: addressId, value: params });
        this.isAddressChanged = true;
    }

    updateZip(params: string, addressId: number): void {
        UsersEntity.updateUserAddressZip({ id: addressId, value: params });
        this.isAddressChanged = true;
    }

    updateIsDefault(params: { name: undefined; id: number; bool: boolean }, addressId: number): void {
        UsersEntity.updateUserAddressIsDefault({ id: addressId, value: params.bool });
        this.isAddressChanged = true;
    }

    saveEdit(): void {
        SiteModule.SET_IS_BLOCK(true);
        UsersEntity.sendAddressEdit(+this.address.id);
        SiteModule.SET_IS_BLOCK(false);
        this.isAddressChanged = false;
    }

    saveAdd(): void {
        SiteModule.SET_IS_BLOCK(true);
        UsersEntity.sendAddressCreate();
        SiteModule.SET_IS_BLOCK(false);
        this.isAddressChanged = false;
    }

    removeHandler(): void {
        this.isRemoved = true;
        this.$emit('update');
    }
}
