import { Module, getModule, Action, Mutation } from 'vuex-module-decorators';
import PageBaseModule from '../page';

import store from '@/admin/store';
import { getUserLogs } from '@/admin/api/logs';
import { generateFilter, showError } from '@/lib/utils/Utils';
import { TableApiInterface, TableInterface } from '@/lib/layouts/page/table.interface';

const prepareTable = (table: TableApiInterface): TableInterface => {
    const hiddenColumns = Object.values(table.titles)
        .filter((title) => title.visible === false || title.id === 'userId')
        .map((title) => title.id);

    return {
        titles: table.titles,
        rows: table.rows,
        sortFields: table.sortFields,
        pagination: table.pagination ? table.pagination : {},
        totalItems: table.totalItems,
        filters: table.filters,
        hiddenColumns: hiddenColumns,
    } as TableInterface;
};

@Module({ dynamic: true, store, name: 'UserLogs', namespaced: true })
class UserLogs extends PageBaseModule {
    userId: string | null = null;

    constructor(module: UserLogs) {
        super(module);
    }

    @Mutation
    SET_USER_ID(id: number | string) {
        this.userId = String(id);
    }

    @Action({ rawError: true })
    async init(userId: string) {
        this.SET_USER_ID(userId);
        this.setDefaultSort();
        this.getList();
    }

    @Action({ rawError: true })
    async getList() {
        if (!this.userId) {
            return;
        }

        try {
            this.SET_IS_LOADING(true);

            const filter = generateFilter({ id: 'user', value: this.userId });
            const table = await getUserLogs(
                this.pageSettings.pageCurrent,
                await this.getSortForRequest(),
                `&${filter}`,
                this.pageSettings.pageAmountItems,
            );

            this.SET_TABLE(prepareTable(table));

            this.SET_IS_LOADING(false);
        } catch (error) {
            this.SET_IS_LOADING(false);
            showError(error as string);
        }
    }

    @Action({ rawError: true })
    reset() {
        this.RESET_TABLE();
    }
}
export default getModule(UserLogs);
