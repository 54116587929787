import InputEntity from '@/lib/formFactory/inputEntity';
import RadioEntity from '@/lib/formFactory/radioEntity';
import InputSuggestionsEntity from '@/lib/formFactory/inputSuggestionsEntity';
import SelectEntity from '@/lib/formFactory/selectEntity';

export default class EntityModel {
    model: any;

    constructor() {
        const operationTypeEntity = new RadioEntity('operationType');
        operationTypeEntity.setTitle('');
        operationTypeEntity.setList([
            { id: 0, name: 'Поступление', value: 'receipt' },
            { id: 1, name: 'Выплата', value: 'pay' },
        ]);
        operationTypeEntity.setRequired(true);
        operationTypeEntity.setValue('receipt');

        const sumEnity = new InputEntity('sum');
        sumEnity.setTitle('Сумма');
        sumEnity.setRequired(true);

        const customerEntity = new InputSuggestionsEntity('customer');
        customerEntity.setPlaceholder('Введите имя покупателя');
        customerEntity.setValidation({
            empty: 'Поле не заполнено',
        });
        customerEntity.setRequired(true);

        const sellerTypeFormEntity = new SelectEntity('sellerType');
        sellerTypeFormEntity.setTitle('Тип продавца');
        sellerTypeFormEntity.setList([
            { id: 'sellerAuctionHouse', value: 'Аукционный дом' },
            { id: 'sellerGallery', value: 'Галерея' },
            { id: 'sellerPainter', value: 'Автор' },
        ]);
        sellerTypeFormEntity.setValidation({
            empty: 'Поле не заполнено',
        });
        sellerTypeFormEntity.setRequired(true);

        const sellerEntity = new InputSuggestionsEntity('seller');
        sellerEntity.setTitle('Продавец');
        sellerEntity.setValidation({
            empty: 'Поле не заполнено',
        });
        sellerEntity.setRequired(true);

        const catalogEntity = new InputSuggestionsEntity('catalog');
        catalogEntity.setTitle('Каталог');
        catalogEntity.setValidation({
            empty: 'Поле не заполнено',
        });

        const orderEntity = new InputSuggestionsEntity('order');
        orderEntity.setTitle('Заказ');
        orderEntity.setValidation({
            empty: 'Поле не заполнено',
        });
        orderEntity.setRequired(true);

        const commentEnity = new InputEntity('comment');
        commentEnity.setTitle('Комментарий');

        this.model = {
            operationType: operationTypeEntity.entity[operationTypeEntity.entityName],
            sum: sumEnity.entity[sumEnity.entityName],
            customer: customerEntity.entity[customerEntity.entityName],
            order: orderEntity.entity[orderEntity.entityName],
            comment: commentEnity.entity[commentEnity.entityName],
            customerDebt: {
                title: '',
                value: '-',
            },
            sellerLink: {
                id: 0,
                name: '',
                type: ',',
                paramName: '',
            },
            orderDetails: {
                costLots: {
                    title: 'Стоимость всех лотов',
                    value: '-',
                },
                costDelivery: {
                    title: 'Стоимость всех доставок',
                    value: '-',
                },
                commissionRightToFollow: {
                    title: 'Комиссия за право следования',
                    value: '-',
                },
                commissionAuctionHouse: {
                    title: 'Комиссия АД',
                    value: '-',
                },
                commissionSite: {
                    title: 'Комиссия площадки',
                    value: '-',
                },
                commissionFinancialServices: {
                    title: 'Комиссия за финансовые операции',
                    value: '-',
                },
                commissionAcquiring: {
                    title: 'Комиссия за эквайринг',
                    value: '-',
                },
                costOrder: {
                    title: 'Стоимость заказа',
                    value: '-',
                },
            },
        };
    }
}
