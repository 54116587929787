<template>
    <div class="cp-input-main__wrapper" :class="{ disabled: disabled, 'required-field-wrapper': data.required  }">
        <label>
            <span
                v-if="data.title"
                class="mr-input-default__title cd-form-input-label"
                :class="{ 'cd-form-input-label-active': isLabelActive || data.value }"
            >
                {{ data.title }}
            </span>

            <span class="mr-input-default">
                <input
                    :type="type"
                    :placeholder="data.placeholder"
                    :value="data.value"
                    class="cp-input-radius-50"
                    :class="data.error.class"
                    :maxlength="data.size"
                    :disabled="disabled"
                    @input="onInput"
                    @change="onInput"
                    @focusin="addClassIn"
                    @focusout="removeClassIn"
                />
                <span class="mr-input-default__eye" @click="toggleType">
                    <icon-viewer color="#D8D8F1" :is-hidden="isHidden" />
                </span>
            </span>
        </label>

        <span class="cp-error-message">{{ data.error.message }}</span>
    </div>
</template>

<script>
import IconViewer from '@/components/icons/Viewer.vue';

export default {
    components: {
        IconViewer,
    },
    props: {
        data: {
            type: Object,
            default() {
                return {
                    key: 'login',
                    type: 'text',
                    title: 'Логин',
                    placeholder: 'Введите имя',
                    value: null,
                    action: 'user/auth/updateLogin',
                    errorHandler: 'user/auth/setValidateState',
                    required: true,
                    size: 250,
                    mask: {
                        regex: '[a-zA-ZА-Яа-я+\\s]+',
                        placeholder: ' ',
                        minChars: 1,
                    },
                    error: {
                        class: '',
                        message: '',
                    },
                    validation: {
                        empty: 'Поле не заполнено',
                        incorrect: 'Некоректное имя',
                    },
                };
            },
        },
        additionalData: null,
        disabled: {
            type: Boolean,
        },
    },
    data() {
        return {
            type: 'password',
            isHidden: true,
            classes: {
                in: 'cd-label-active',
            },
            isLabelActive: false,
        };
    },
    methods: {
        addClassIn() {
            this.isLabelActive = true;
        },
        removeClassIn() {
            this.isLabelActive = false;
        },
        onInput(e) {
            if (this.additionalData != null) {
                this.$emit('input', { value: e.target.value, param: this.additionalData });
            } else {
                this.$emit('input', e.target.value);
            }
        },
        toggleType() {
            this.type = this.type === 'password' ? 'text' : 'password';
            this.isHidden = !this.isHidden;
        },
    },
};
</script>
