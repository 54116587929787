import { UserModelInterface } from '@/interfaces/models/user.interface';
import CheckboxEntity from '@/lib/formFactory/checkboxEntity';
import InputEntity from '@/lib/formFactory/inputEntity';
import InputSuggestionEntity from '@/lib/formFactory/inputSuggestionsEntity';
import SelectEntity from '@/lib/formFactory/selectEntity';

export default class EntityModel {
    model: UserModelInterface;

    constructor() {
        const firstNameEntity = new InputEntity('firstName');
        firstNameEntity.setTitle('Имя');
        firstNameEntity.setRequired(true);

        const middleNameEntity = new InputEntity('middleName');
        middleNameEntity.setTitle('Отчество');

        const lastNameEntity = new InputEntity('lastName');
        lastNameEntity.setTitle('Фамилия');

        const emailEntity = new InputEntity('email');
        emailEntity.setTitle('Email');
        emailEntity.setMask({
            mask: '*{1,20}[.*{1,20}][.*{1,20}][.*{1,20}]@*{1,20}[.*{2,20}][.*{1,20}]',
            greedy: false,
            definitions: {
                '*': {
                    validator: "[0-9A-Za-z!#$%&'*+/=?^_`{|}~-]",
                    casing: 'lower',
                },
            },
        });
        emailEntity.setValidation({
            empty: 'Поле не заполнено',
            regex: 'Неверный формат почтового адреса',
        });
        emailEntity.setRequired(true);

        const phoneCodeEntity = new SelectEntity('phoneCode');
        phoneCodeEntity.setTitle('Код');

        const phoneEntity = new InputEntity('phone');
        phoneEntity.setTitle('Телефон');
        phoneEntity.setMask({
            greedy: false,
            showMaskOnHover: false,
            placeholder: '_',
        });
        phoneEntity.setValidation({
            empty: 'Поле не заполнено',
            length: 'Телефон не соответствует формату +7 (XXX) XXX-XX-XX',
        });
        phoneEntity.setRequired(true);

        const passwordEntity = new InputEntity('password');
        passwordEntity.setTitle('Пароль');
        passwordEntity.setRequired(true);

        const confirmPasswordEntity = new InputEntity('confirm_password');
        confirmPasswordEntity.setTitle('Подтвердите пароль');
        confirmPasswordEntity.setRequired(true);

        const isActiveEntity = new CheckboxEntity('isActive');
        isActiveEntity.setList({
            0: {
                id: 1,
                name: 'Активен',
                checked: false,
            },
        });

        const isPaymentDetailsConfirmedEntity = new CheckboxEntity('isPaymentDetailsConfirmed');
        isPaymentDetailsConfirmedEntity.setList({
            0: {
                id: 1,
                name: 'Платежные данные подтверждены',
                checked: false,
            },
        });

        const auctionHouseEntity = new InputSuggestionEntity('auctionHouse');
        auctionHouseEntity.setTitle('Аукционный дом');

        const auctionHouseRoleEntity = new SelectEntity('auctionHouseRole');
        auctionHouseRoleEntity.setTitle('Роль');
        auctionHouseRoleEntity.setList([
            { id: 'admin', value: 'Администратор АД' },
            { id: 'operator', value: 'Оператор АД' },
        ]);

        const sellerGalleryEntity = new InputSuggestionEntity('sellerGallery');
        sellerGalleryEntity.setTitle('Галерея');

        const sellerPainterEntity = new InputSuggestionEntity('sellerPainter');
        sellerPainterEntity.setTitle('Автор');

        const sellerTypeEntity = new SelectEntity('sellerType');
        sellerTypeEntity.setTitle('Тип продавца');
        sellerTypeEntity.setList([
            { id: 'auction_house', value: 'Аукционный дом' },
            { id: 'gallery', value: 'Галерея' },
            { id: 'painter', value: 'Автор' },
        ]);
        sellerTypeEntity.setClearAction('usersEntity/clearSellerType');

        const publicRoleEntity = new SelectEntity('publicRole');
        publicRoleEntity.setTitle('Публичная роль');
        publicRoleEntity.setClearAction('usersEntity/clearPublicRole');

        this.model = {
            firstName: firstNameEntity.entity[firstNameEntity.entityName],
            middleName: middleNameEntity.entity[middleNameEntity.entityName],
            lastName: lastNameEntity.entity[lastNameEntity.entityName],
            email: emailEntity.entity[emailEntity.entityName],
            phone: phoneEntity.entity[phoneEntity.entityName],
            phoneCode: phoneCodeEntity.entity[phoneCodeEntity.entityName],
            password: passwordEntity.entity[passwordEntity.entityName],
            confirmPassword: confirmPasswordEntity.entity[confirmPasswordEntity.entityName],
            addresses: [],
            isActive: isActiveEntity.entity[isActiveEntity.entityName],
            isPaymentDetailsConfirmed:
                isPaymentDetailsConfirmedEntity.entity[isPaymentDetailsConfirmedEntity.entityName],
            createdAt: '',
            sellerAuctionHouse: auctionHouseEntity.entity[auctionHouseEntity.entityName],
            sellerAuctionHouseRole: auctionHouseRoleEntity.entity[auctionHouseRoleEntity.entityName],
            sellerGallery: sellerGalleryEntity.entity[sellerGalleryEntity.entityName],
            sellerPainter: sellerPainterEntity.entity[sellerPainterEntity.entityName],
            sellerType: sellerTypeEntity.entity[sellerTypeEntity.entityName],
            balance: null,
            isOnline: false,
            publicRole: publicRoleEntity.entity[publicRoleEntity.entityName],
            balanceDetails: {
                countBid: {
                    title: 'Сделано ставок',
                    value: 0,
                },
                lotsBought: {
                    title: 'Куплено лотов',
                    value: 0,
                },
                totalValueLots: {
                    title: 'На сумму',
                    value: '',
                },
                avgLotValue: {
                    title: 'Средняя стоимость лота',
                    value: '',
                },
                avgReceivedFromLot: {
                    title: 'В среднем получено с одного лота',
                    value: '',
                },
            },
            status: '',
        };
    }
}
