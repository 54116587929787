import { Module, getModule, Action } from 'vuex-module-decorators';
import TransactionEntityBaseModule from '@/admin/store/transactionsCustomerEntity';

import store from '@/admin/store';
import EntityModel from './transactionModel';
import { generateSort } from '@/lib/utils/Utils';
import { InputSuggestionItem } from '@/lib/formFactory/inputSuggestion.interface';

const MODULE_NAME = 'UserTransaction';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class CustomerTransactionEntity extends TransactionEntityBaseModule {
    model: any;
    selectDefault = { id: 0, value: '' };
    requestSort = '';

    constructor(module: CustomerTransactionEntity) {
        super(module);

        const entityModel = new EntityModel();
        this.model = entityModel.model;
        this.requestSort = generateSort({ id: 'name', value: 'ASC' });
    }

    @Action({ rawError: true })
    initCustomerInfo(customer: InputSuggestionItem) {
        if (!customer.id) {
            return;
        }

        this.updateSuggestionsSelectedByType({ type: 'customer', selected: customer });
    }
}
export default getModule(CustomerTransactionEntity);
