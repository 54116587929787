import API from '@/admin/config/api-routers';
import { makeDeleteRequest, makeFormRequest } from '@/api/helpers';
import { AddressRequestInterface } from '@/interfaces/address.interface';
import { UserCityItem } from '@/interfaces/users.interface';

export const addressCreate = async (data: AddressRequestInterface): Promise<UserCityItem> => {
    const result = await makeFormRequest(API.address.create, data);

    return result.data.result.item;
};

export const addressEdit = async (addressId: number, data: AddressRequestInterface): Promise<UserCityItem> => {
    const result = await makeFormRequest(`${API.address.card}${addressId}/`, data);

    return result.data.result.item;
};

export const addressDelete = async (addressId: number): Promise<{ status: string }> => {
    const result = await makeDeleteRequest(`${API.address.card}${addressId}/`);

    return result.data.result.status;
};
