
import { Vue, Component } from 'vue-property-decorator';

import FlexLayout from '@/admin/layouts/FlexLayout.vue';
import Loading from '@/components/Loading.vue';
import TableBlock from '@/components/table/Table.vue';

import UserLogsModule from '@/admin/store/users/logs';
import { PageInterface } from '@/lib/layouts/page/page.interface';

@Component({
    components: {
        FlexLayout,
        Loading,
        TableBlock,
    },
})
export default class UserLogsComponent extends Vue {
    get isLoading(): boolean {
        return UserLogsModule.isLoading;
    }

    get settings(): PageInterface {
        return UserLogsModule.pageSettings;
    }

    get paginationAction(): (number: string) => void {
        return UserLogsModule.updatePage;
    }

    get paginationItems(): { items: string } {
        return {
            items: UserLogsModule.pageSettings.pageAmountItems,
        };
    }

    async selectAmount(items: string): Promise<void> {
        UserLogsModule.updatePageAmountItems(items);
    }

    created(): void {
        UserLogsModule.init(this.$route.params.userId);
    }

    beforeDestroy(): void {
        UserLogsModule.reset();
    }
}
