/**
 * Transaction entity base module
 *
 */

import { Mutation, Action } from 'vuex-module-decorators';

import EntityBaseModule from '@/admin/store/entity';
import { getOrderCard, getOrdersList } from '@/admin/api/orders';
import { OrderTableItemInterface } from '@/interfaces/orders.interface';
import { SelectItem } from '@/lib/formFactory/select.interface';

import ResponseHandlerModule from '@/admin/store/modules/responseHandler';
import { cancelTransaction, createTransaction } from '@/admin/api/transaction';
import { InputSuggestionItem } from '@/lib/formFactory/inputSuggestion.interface';
import { getCatalogAuctionList } from '../api/catalogAuction';
import { processListForSelect } from '@/lib/utils/form';
import { generateFilter, getApiErrorMessage, showError } from '@/lib/utils/Utils';

export default abstract class FinanceCustomerEntity extends EntityBaseModule {
    model: any;
    selectDefault = { id: 0, value: '' };
    requestSort = '';
    isSendBlocked = false;

    constructor(module: FinanceCustomerEntity) {
        super(module);
    }

    @Mutation
    SET_IS_SEND_BLOCKED(bool: boolean) {
        this.isSendBlocked = bool;
    }

    @Mutation
    SET_OPERATION_TYPE(value: string) {
        this.model.operationType.value = value;
    }

    @Mutation
    SET_SUM(sum: string) {
        this.model.sum.value = sum;
    }

    @Mutation
    SET_ORDER(order: string) {
        this.model.order.value = order;
    }

    @Mutation
    SET_ORDER_SELECTED(selected: SelectItem) {
        this.model.order.current = selected;
    }

    @Mutation
    SET_SELLER_LINK_ID(id: number | null) {
        this.model.sellerLink.id = id;
    }

    @Mutation
    SET_SELLER_LINK_NAME(name: string) {
        this.model.sellerLink.name = name;
    }

    @Mutation
    SET_SELLER_LINK_TYPE(type: string) {
        this.model.sellerLink.type = type;
    }

    @Mutation
    SET_SELLER_LINK_PARAM_NAME(name: string) {
        this.model.sellerLink.paramName = name;
    }

    @Mutation
    SET_COST_LOTS(cost: number | string) {
        this.model.orderDetails.costLots.value = cost;
    }

    @Mutation
    SET_COST_DELIVERY(cost: number | string) {
        this.model.orderDetails.costDelivery.value = cost;
    }

    @Mutation
    SET_COMMISSION_RIGHT_TO_FOLLOW(commission: number | string) {
        this.model.orderDetails.commissionRightToFollow.value = commission;
    }

    @Mutation
    SET_COMMISSION_AUCTION_HOUSE(commission: number | string) {
        this.model.orderDetails.commissionAuctionHouse.value = commission;
    }

    @Mutation
    SET_COMMISSION_FINANCIAL_SERVICES(commission: number | string) {
        this.model.orderDetails.commissionFinancialServices.value = commission;
    }

    @Mutation
    SET_COMMISSION_SITE(commission: number | string) {
        this.model.orderDetails.commissionSite.value = commission;
    }

    @Mutation
    SET_COMMISSION_ACQUIRING(commission: number | string) {
        this.model.orderDetails.commissionAcquiring.value = commission;
    }

    @Mutation
    SET_CATALOG_IS_REQUIRED(bool: boolean) {
        this.model.catalog.required = bool;
    }

    @Mutation
    SET_ORDER_DETAILS_ITEM(params: { key: string; value: string }): void {
        if (!this.model.orderDetails?.[params.key]) {
            return;
        }

        this.model.orderDetails[params.key].value = params.value;
    }

    @Action({ rawError: true })
    updateOperationType(type: string) {
        this.context.commit('SET_OPERATION_TYPE', type);
    }

    @Action({ rawError: true })
    updateSum(sum: string) {
        this.context.commit('RESET_ERROR_BY_FIELD', 'sum');
        this.context.commit('SET_SUM', sum);
    }

    @Action({ rawError: true })
    updateOrder(order: string) {
        this.context.commit('SET_ORDER', order);
    }

    @Action({ rawError: true })
    updateSelectedCustomer(selected: InputSuggestionItem) {
        this.context.commit('RESET_ERROR_BY_FIELD', 'customer');
        this.context.dispatch('updateSuggestionsSelectedByType', { type: 'customer', selected });
    }

    @Action({ rawError: true })
    async updateSearchCustomerOrder(search: string) {
        let filter = search ? `&filters[0][id]=id&filters[0][value]=${search}` : '';
        filter += this.model.customer.current.id
            ? `&filters[1][id]=customer&filters[1][value]=${this.model.customer.current.id}`
            : '';

        this.context.dispatch('updateSearchOrder', { search, filter });
    }

    @Action({ rawError: true })
    async updateSearchSellerOrder(params: { search: string; sellerType: string; sellerFilterName: string }) {
        let filter = params.search ? `&filters[0][id]=id&filters[0][value]=${params.search}` : '';
        filter += `&filters[1][id]=sellerType&filters[1][value]=${params.sellerType}`;
        filter += this.model.seller.current.id
            ? `&filters[2][id]=${params.sellerFilterName}&filters[2][value]=${this.model.seller.current.id}`
            : '';
        // filter += this.model.catalog?.current.id ? `&filters[3][id]=auction&filters[3][value]=${this.model.catalog.current.id}` : '';

        this.context.dispatch('updateSearchOrder', { search: params.search, filter });
    }

    @Action({ rawError: true })
    async updateSearchOrder(params: { search: string; filter: string }) {
        this.context.commit('SET_SUGGESTIONS_VALUE_BY_TYPE', { type: 'order', value: params.search });

        const table = await getOrdersList(1, this.requestSort, params.filter, 50);
        const list = table.rows.map((row: OrderTableItemInterface) => {
            return {
                id: row.id,
                value: row.id,
            };
        });

        this.context.commit('SET_SUGGESTIONS_HINTS_LIST_BY_TYPE', { type: 'order', list });
    }

    @Action({ rawError: true })
    async updateSelectedOrder(selected: InputSuggestionItem) {
        if (this.model.sellerLink) {
            this.context.commit('SET_SELLER_LINK_ID', null);
            this.context.commit('SET_SELLER_LINK_NAME', '');
            this.context.commit('SET_SELLER_LINK_TYPE', '');
            this.context.commit('SET_SELLER_LINK_PARAM_NAME', '');
        }

        this.context.commit('SET_ORDER', selected.value);
        this.context.commit('SET_ORDER_SELECTED', selected);

        if (!selected.id) {
            this.context.dispatch('resetOrderInfo');
            this.context.dispatch('updateSearchOrder');

            return;
        }

        this.context.commit('RESET_ERROR_BY_FIELD', 'order');

        try {
            this.context.commit('SET_IS_LOADING', true);

            const order = await getOrderCard(selected.id);
            Object.keys(order).forEach((key) => {
                this.context.commit('SET_ORDER_DETAILS_ITEM', { key, value: order[key] || 0 });
            });

            if (order.sellerAuctionHouse !== null && this.model.sellerLink) {
                this.context.commit('SET_SELLER_LINK_TYPE', 'auction_house');
                this.context.commit('SET_SELLER_LINK_PARAM_NAME', 'auctionHouseId');
                this.context.commit('SET_SELLER_LINK_ID', order.sellerAuctionHouse.id);
                this.context.commit('SET_SELLER_LINK_NAME', order.sellerAuctionHouse.name);
            }

            if (order.sellerGallery !== null && this.model.sellerLink) {
                this.context.commit('SET_SELLER_LINK_TYPE', 'gallery');
                this.context.commit('SET_SELLER_LINK_PARAM_NAME', 'galleryId');
                this.context.commit('SET_SELLER_LINK_ID', order.sellerGallery.id);
                this.context.commit('SET_SELLER_LINK_NAME', order.sellerGallery.name);
            }

            if (order.sellerPainter !== null && this.model.sellerLink) {
                this.context.commit('SET_SELLER_LINK_TYPE', 'painter');
                this.context.commit('SET_SELLER_LINK_PARAM_NAME', 'painterId');
                this.context.commit('SET_SELLER_LINK_ID', order.sellerPainter.id);
                this.context.commit('SET_SELLER_LINK_NAME', order.sellerPainter.name);
            }

            this.context.commit('SET_IS_LOADING', false);
        } catch (error) {
            this.context.commit('SET_IS_LOADING', false);
        }
    }

    @Action({ rawError: true })
    async updateSearchCatalog(search: string): Promise<void> {
        this.context.commit('SET_SUGGESTIONS_VALUE_BY_TYPE', { type: 'catalog', value: search });

        const filterItems = [{ id: 'withRemoved', value: '1' }];

        if (search) {
            filterItems.push({ id: 'name', value: search });
        }

        if (this.model.seller.current.id) {
            filterItems.push({ id: 'sellerAuctionHouseId', value: this.model.seller.current.id });
        }

        const table = await getCatalogAuctionList(1, this.requestSort, generateFilter(filterItems), 50);
        this.context.commit('SET_SUGGESTIONS_HINTS_LIST_BY_TYPE', {
            type: 'catalog',
            list: processListForSelect(table.rows),
        });
    }

    @Action({ rawError: true })
    validate() {
        const requiredFields = Object.keys(this.model).filter((key) => this.model[key]?.required === true);

        this.context.dispatch('setValidateState', { index: 'sum', field: this.model.sum });
        this.context.dispatch('setValidateSelect', { index: 'customer', field: this.model.customer });
        this.context.dispatch('setValidateSelect', { index: 'order', field: this.model.order });
        this.context.dispatch('setValidateRadio', { index: 'operationType', field: this.model.operationType });

        const fieldsWithError = requiredFields.filter((field) => this.model[field]?.error?.class === 'cp-error');

        return fieldsWithError.length > 0 ? true : false;
    }

    @Action({ rawError: true })
    prepareData() {
        const data: {
            operationType: string;
            sum: number;
            customer: number;
            order: number;
            comment?: string;
        } = {
            operationType: this.model.operationType.value,
            sum: this.model.sum.value,
            order: this.model.order.current.id,
            customer: this.model.customer.current.id,
        };

        if (this.model.comment.value) {
            data['comment'] = this.model.comment.value;
        }

        return data;
    }

    @Action({ rawError: true })
    async send() {
        const isError = await this.context.dispatch('validate');

        if (isError) {
            ResponseHandlerModule.showNotify({ message: 'Заполните обязательные поля', type: 'fail' });

            return isError;
        }

        this.context.commit('SET_IS_SEND_BLOCKED', true);
        try {
            const data = await this.context.dispatch('prepareData');
            const result = await createTransaction(data);

            if (!result.message) {
                ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });
                this.context.dispatch('resetAfterSend');
            } else {
                ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
            }
        } catch (error) {
            showError(getApiErrorMessage(error));
        }
        this.context.commit('SET_IS_SEND_BLOCKED', false);
    }

    @Action({ rawError: true })
    async cancelTransaction(transactionId: number) {
        try {
            const result = await cancelTransaction(transactionId);

            if (!result.message) {
                ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });
            } else {
                ResponseHandlerModule.showNotify({ message: `Транзакция №${transactionId} отменена`, type: 'ok' });
            }
        } catch (error) {
            showError(getApiErrorMessage(error));
        }
    }

    @Action({ rawError: true })
    resetAfterSend() {
        this.context.commit('SET_SUM', '');
        this.context.commit('SET_COMMENT', '');
        this.context.dispatch('updateSuggestionsSelectedByType', { type: 'order', selected: { id: 0, value: '' } });
        this.context.commit('SET_SUGGESTIONS_HINTS_LIST_BY_TYPE', { type: 'order', list: [] });

        this.context.dispatch('resetOrderInfo');
    }

    @Action({ rawError: true })
    reset() {
        this.context.commit('SET_OPERATION_TYPE', 'receipt');
        this.context.commit('SET_SUM', '');
        this.context.commit('SET_COMMENT', '');

        if (this.model.customer) {
            this.context.dispatch('updateSuggestionsSelectedByType', {
                type: 'customer',
                selected: { id: 0, value: '' },
            });
            this.context.commit('SET_SUGGESTIONS_HINTS_LIST_BY_TYPE', { type: 'customer', list: [] });
        }

        if (this.model.seller) {
            this.context.dispatch('updateSuggestionsSelectedByType', {
                type: 'seller',
                selected: { id: 0, value: '' },
            });
            this.context.commit('SET_SUGGESTIONS_HINTS_LIST_BY_TYPE', { type: 'seller', list: [] });
        }

        if (this.model.catalog) {
            this.context.dispatch('updateSuggestionsSelectedByType', {
                type: 'catalog',
                selected: { id: 0, value: '' },
            });
            this.context.commit('SET_SUGGESTIONS_HINTS_LIST_BY_TYPE', { type: 'catalog', list: [] });
        }

        this.context.dispatch('updateSuggestionsSelectedByType', { type: 'order', selected: { id: 0, value: '' } });
        this.context.commit('SET_SUGGESTIONS_HINTS_LIST_BY_TYPE', { type: 'order', list: [] });

        this.context.dispatch('resetOrderInfo');
    }

    @Action({ rawError: true })
    resetOrderInfo() {
        this.context.commit('SET_SELLER_LINK_ID', null);
        this.context.commit('SET_SELLER_LINK_NAME', '');
        this.context.commit('SET_SELLER_LINK_TYPE', '');
        this.context.commit('SET_SELLER_LINK_PARAM_NAME', '');

        Object.keys(this.model.orderDetails).forEach((key) => {
            this.context.commit('SET_ORDER_DETAILS_ITEM', { key, value: '' });
        });

        this.context.dispatch('resetErrorState');
    }

    @Action({ rawError: true })
    resetErrorState() {
        this.context.commit('RESET_ERROR_BY_FIELD', 'sum');
        this.context.commit('RESET_ERROR_BY_FIELD', 'order');

        if (this.model.seller) {
            this.context.commit('RESET_ERROR_BY_FIELD', 'seller');
        }

        if (this.model.catalog) {
            this.context.commit('RESET_ERROR_BY_FIELD', 'catalog');
        }

        if (this.model.customer) {
            this.context.commit('RESET_ERROR_BY_FIELD', 'customer');
        }
    }
}
