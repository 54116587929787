import { makeGetRequest } from '@/api/helpers';
import API from '@/admin/config/api-routers';
import { TableApiInterface } from '@/lib/layouts/page/table.interface';

export const getUserLogs = async (
    page = 1,
    sort = '',
    filter = '',
    items: number | string = 50,
): Promise<TableApiInterface> => {
    let requestParamsString = `?page=${page}`;

    if (sort) {
        requestParamsString += sort;
    }

    if (filter) {
        requestParamsString += filter;
    }

    if (items) {
        requestParamsString += `&items=${items}`;
    }

    const result = await makeGetRequest(`${API.logs.getList}${requestParamsString}`);

    return result.data.result.table;
};
