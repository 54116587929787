
import { Vue, Component } from 'vue-property-decorator';
import Layout from '@/admin/layouts/Default.vue';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import TitleReturn from '@/components/TitleReturn.vue';
import CardBlock from './_card.vue';
import BalanceBlock from './_balance.vue';
import LogsBlock from './_logs.vue';

import UserEntity from '@/admin/store/users/entity';
import UserTransactionEntity from '@/admin/store/users/transactionEntity';
import { tabsNavInterface } from '@/interfaces/tabs';

enum userStatusEnum {
    ONLINE = 'онлайн',
    OFFLINE = 'офлайн',
}

@Component({
    components: {
        Layout,
        TabsNav,
        TitleReturn,
        CardBlock,
        BalanceBlock,
        LogsBlock,
    },
})
export default class UserComponent extends Vue {
    returnTitleData = {
        icon: 'users',
        title: '',
    };

    get isShowTabs(): boolean {
        return this.$route.name !== 'user_create';
    }

    get tabsNav(): tabsNavInterface {
        return UserEntity.tabsNav;
    }

    get currentTab(): string {
        if ((this.$route.name as string) in this.tabsNav) {
            return this.tabsNav[this.$route.name as string].id;
        }

        return this.tabsNav.user.id;
    }

    get userOnlineStatus(): boolean {
        return UserEntity.model.isOnline;
    }

    get userOnlineStatusName(): string {
        return this.userOnlineStatus ? userStatusEnum.ONLINE : userStatusEnum.OFFLINE;
    }

    returnBefore(): void {
        this.$router.push({ name: 'users' });
    }

    created(): void {
        UserEntity.initTabsItems();
    }

    async mounted(): Promise<void> {
        await Promise.all([UserEntity.initForm(), UserEntity.getPhoneCodes()]);

        if (this.$route.params.userId && !UserEntity.cardId) {
            await UserEntity.initCardById(this.$route.params.userId);
            const userName = [
                UserEntity.model.firstName.value,
                UserEntity.model.middleName.value,
                UserEntity.model.lastName.value,
            ]
                .filter((name) => name)
                .join(' ');
            UserTransactionEntity.initCustomerInfo({
                id: UserEntity.cardId ?? 0,
                value: userName,
            });
            this.returnTitleData = UserEntity.titleReturnData.edit;
        }

        if (this.$route.name === 'user_create') {
            this.returnTitleData = UserEntity.titleReturnData.create;
        }
    }

    beforeDestroy(): void {
        UserEntity.reset();
    }
}
