export const tabsNav = {
    user: {
        id: 'user',
        name: 'Профиль',
        template: 'user',
        pathName: 'user',
        icon: '',
        iconSize: '16',
    },
    user_balance: {
        id: 'user_balance',
        name: 'Баланс',
        template: 'user_balance',
        pathName: 'user_balance',
        icon: '',
        iconSize: '16',
    },
    user_logs: {
        id: 'user_logs',
        name: 'Действия пользователя',
        template: 'user_logs',
        pathName: 'user_logs',
        icon: '',
        iconSize: '16',
    },
};
