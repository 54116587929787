
import { Vue, Component } from 'vue-property-decorator';

import UserEntity from '@/admin/store/users/entity';
import UserTransactions from '@/admin/store/users/transactions';
import UserTransactionEntity from '@/admin/store/users/transactionEntity';

import Loading from '@/components/Loading.vue';
import TableBlock from '@/components/table/Table.vue';
import WarningModal from '@/components/modules/Warning.vue';
import UserStatistics from './_statistics.vue';
import TransactionForm from '@/components/Transaction.vue';
import { InputSuggestionItem } from '@/lib/formFactory/inputSuggestion.interface';
import UserModule from '@/admin/store/user';
import RoleModule, { RoleEnum } from '@/admin/store/role';

@Component({
    components: {
        Loading,
        TableBlock,
        WarningModal,
        UserStatistics,
        TransactionForm,
    },
})
export default class UserBalanceComponent extends Vue {
    isWarningShow = false;
    texts = {
        no: 'Нет',
        yes: 'Да',
        title: 'Внимание',
        description: 'Вы действительно хотите отменить транзакцию?',
    };
    transactionToDelete = 0;

    get isWriteAllowed(): boolean {
        return RoleModule.accessByRouterName[this.$route.name as RoleEnum]?.write.includes(UserModule.role as RoleEnum);
    }

    get model() {
        return UserEntity.model;
    }

    get settings() {
        return UserTransactions.pageSettings;
    }

    get isTableLoading() {
        return UserTransactions.isLoading;
    }

    get isOrderLoading() {
        return UserTransactionEntity.isLoading;
    }

    get transactionModel() {
        return UserTransactionEntity.model;
    }

    get isSubmitBlocked() {
        return UserTransactionEntity.isSendBlocked;
    }

    async selectAmount(items: string) {
        await UserTransactions.updateItems(items);
        this.requestTable(+this.$route.params.userId);
    }

    get paginationAction() {
        return UserTransactions.updatePage;
    }

    get paginationItems(): { items: string } {
        return {
            items: UserTransactions.pageSettings.pageAmountItems,
        };
    }

    requestTable(customerId: number) {
        if (!customerId) {
            return;
        }

        UserTransactions.getTable({ customerId });
    }

    async tableAction(params: any) {
        if (params.action === 'sort') {
            await UserTransactions.updateSortValue(params);
            this.requestTable(+this.$route.params.userId);
        }

        if (params.action === 'delete') {
            this.transactionToDelete = params.id;
            this.isWarningShow = true;
        }
    }

    transactionFormHandler(event: { action: string; value: string | InputSuggestionItem }) {
        if (event.action === 'operationType') {
            if (typeof event.value !== 'string') {
                return;
            }

            UserTransactionEntity.updateOperationType(event.value);
        }

        if (event.action === 'sum') {
            if (typeof event.value !== 'string') {
                return;
            }

            UserTransactionEntity.updateSum(event.value);
        }

        if (event.action === 'comment') {
            if (typeof event.value !== 'string') {
                return;
            }

            UserTransactionEntity.updateComment(event.value);
        }

        if (event.action === 'searchOrder') {
            if (typeof event.value !== 'string') {
                return;
            }

            UserTransactionEntity.updateSearchCustomerOrder(event.value);
        }

        if (event.action === 'selectOrder') {
            if (typeof event.value === 'string') {
                return;
            }

            UserTransactionEntity.updateSelectedOrder(event.value);
        }
    }

    async removeTransaction() {
        await UserTransactionEntity.cancelTransaction(this.transactionToDelete);

        this.transactionToDelete = 0;
        this.isWarningShow = false;

        this.requestTable(+this.$route.params.userId);
    }

    async submit() {
        const isValidationError = await UserTransactionEntity.send();

        if (isValidationError) {
            return;
        }

        this.requestTable(+this.$route.params.userId);
    }

    closeModal() {
        this.isWarningShow = false;
        this.transactionToDelete = 0;
    }

    created() {
        UserTransactions.setDefaultSort(false);
        this.requestTable(+this.$route.params.userId);
    }
}
