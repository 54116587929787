
import { Component, Mixins } from 'vue-property-decorator';
import StatisticsMixin from '@/components/StatisticsMixin';
import { getStatistics } from '@/admin/api/statistics';
import { formatNumberSpaced } from '@/lib/utils/Utils';

@Component
export default class UserStatisticsComponent extends Mixins(StatisticsMixin) {
    balance: string | number = 0;
    statistics: { [key: string]: { name: string; value: string | number } } = {
        number_bids: {
            name: 'Количество ставок',
            value: 0,
        },
        lots_bought: {
            name: 'Куплено лотов',
            value: 0,
        },
        amount: {
            name: 'Общая сумма с купленных лотов',
            value: '',
        },
        received_avg_from_lot: {
            name: 'В среднем потрачено на один лот',
            value: 0,
        },
        avg_price: {
            name: 'Средняя стоимость лота',
            value: '',
        },
    };

    getFormattedNumberString(value: number | string): string {
        return formatNumberSpaced(value);
    }

    async initStatistics(): Promise<void> {
        this.getStatistics();
    }

    setInfo(data: { [key: string]: number | string }): void {
        this.balance = +data.balance % 1 !== 0 ? (+data.balance).toFixed(2) : data.balance;

        Object.keys(data).forEach((key) => {
            if (this.statistics[key] == null) {
                return;
            }

            let value = data[key];

            if (+data[key] % 1 !== 0) {
                value = (+data[key]).toFixed(2);
            }

            if (key.indexOf('avg') !== -1 || key === 'amount') {
                value = `${formatNumberSpaced(value)} ₽`;
            }

            this.statistics[key].value = value;
        });
    }

    async getStatistics(): Promise<void> {
        const result = await getStatistics('customer', +this.$route.params.userId, this.filterString);

        this.setInfo(result);
    }
}
